<template>
<modal title="Scheme Pension" @close="$emit('close')" :shortIntro="!currentQuestion || currentQuestion == 5">
  <template slot="intro">
    <p v-if="currentQuestion !== 5">You will receive an estimated pension of <span class="amount">£{{schemeCalc.retirement_pension | roundDown(100)}} </span> pa from age {{retirementAgeShow | age}} <span v-if="bridgeTheGap" >reducing by <span class="amount">£{{schemeCalc.post_bridge_reduction | roundDown(100)}}</span> pa at State Pension Age</span> and a cash lump sum of <span class="amount">£{{ schemeCalc.retirement_pcls | roundDown(500)}}</span></p>
  </template>
  <template slot="content">
    <questions-flow ref="questions" @complete="submit">
      <question title="Introduction" :animateText="true">
        <div class="container left">
          <p class="animate">Below we provide an illustration of your key benefits if you remain within the {{ schemeDetails.schemeName }} and the different options you have. <b>The summary benefits, shown above, will change as you change any options in this section</b>.</p>
          <p class="animate">Within the Scheme your normal retirement age is {{user.nra}}, but you can retire {{user.ageNow > user.earliest_retirement_age? 'straight away': 'from age '+ user.earliest_retirement_age}}.  <span v-if="user.ageNow < user.earliest_undreduced_retirement_age && user.earliest_retirement_age < user.earliest_undreduced_retirement_age">However, if retire before {{user.earliest_undreduced_retirement_age}} your pension will be reduced, reflecting that it is in payment for longer.  This is known as early retirement.</span>  Once in payment your pension will increase each year, in line with increases in the cost of living.  <b><span v-if="user.status == 'active'">If you decide to draw your Scheme pension or transfer out you will no longer be able to build up further benefits in the Scheme.</span></b></p>
          <p v-if="seeSlider"> To see how changing your retirement age changes your estimated retirement benefits under the various options please go back and use the sliding scale above the illustration summary.</p>
          <p v-if="user.additional_pension_purchased_using_added_years_avcs > 0">The above pension includes your added years AVCs (for further details please click <a href="https://cadentgaspensions.com/deferred-members/documents" target="_blank">here</a>).</p>
          <p v-if="schemeCalc.gmp_step_up > 100">In addition when you reach age {{schemeCalc.gmp_age}} your pension will increase by approximately £{{schemeCalc.gmp_step_up | roundDown(100)}} pa.  This figure is not guaranteed as it will depend on future increases in the cost of living (for further details please click <a href="https://cadentgaspensions.com/pensioner-members/about-your-pension/guaranteed-minimum-pension/" target="_blank">here</a>).</p>
          <p v-if="schemeCalc.gmp_step_up_applied > 0.001">The figures above include an increase when you retire from age {{schemeCalc.gmp_age}} – these figures are not guaranteed as it will depend on future increases in the cost of living and other factors (for further details please click <a href="https://cadentgaspensions.com/pensioner-members/about-your-pension/guaranteed-minimum-pension/" target="_blank">here</a>).</p>
          <p class="animate">In the following sections you can see:</p>
          <ul>
            <li class="animate">The option for giving up part of your pension for a tax free cash lump sum when you retire.</li>
            <li class="animate">The option to take a higher pension now until your State Pension Age.</li>
            <li class="animate">The benefits your spouse or other dependants will receive at the time of your death.</li>
          </ul>
        </div>
      </question>
      <question title="Tax free cash lump sum" :animateText="true">
        <div class="container left">
          <p class="animate">When you retire you can choose to give up some of your pension for a cash lump sum. In many cases this can be taken tax free but this will depend on any other retirement benefits you are due to receive. Move the slider below to see how much you could receive as a lump sum and how this will affect your pension.</p>
          <div class="post-animate">
            <div>
              <vue-slider v-model="cashAmountPercentage" :real-time="true" :data="cashSliderDataPoints" :piecewise="true" :piecewiseLabel="true" :speed="0" :min="0" :tooltip="false" :max="100" :dotSize="28">
                <template slot="label" slot-scope="{ label }">
                    <span class="vue-slider-piecewise-label" v-if="label === 0">Minimum</span>
                    <span class="vue-slider-piecewise-label" v-if="label === 100">Maximum</span>
                </template>
                <template slot="piecewise" slot-scope="{ label, active }"><span class="vue-slider-piecewise-dot year" :class="{ active: active }" v-if="label % 10 === 0"></span></template>
              </vue-slider>
            </div>
            <p class="slider-amount">Taking <span class="amount">£{{ schemeCalc.retirement_pcls | roundDown(500) }}</span> cash <span v-if="schemeCalc.pension_used_in_commutation > 0">(in exchange of £{{schemeCalc.pension_used_in_commutation | roundDown(100)}} pension per annum)</span></p>
            <p v-if="user.mp_avcs_value > 0">Your Money Purchase Additional Voluntary Contributions (MP AVCs) in the Scheme are normally used in the first instance to provide any tax-free cash sum you request. Further details can be found in the <a href="https://cadentgaspensions.com/deferred-members/documents" target="_blank">AVC Guide</a></p>
            <p v-if="user.mp_avcs_value > 500">Your AVC value is <span class="amount">£{{user.mp_avcs_value | roundDown(500)}} </span> at {{user.calculation_date.format('MMM YYYY')}} and for the illustration above we have assumed this will be your minimum cash lump sum.</p>
            <p v-if="user.mp_avcs_value > 0">As an alternative when you retire, you can transfer out your MP AVCs to another pension provider.</p>
            <p v-if="schemeCalc.arrears_cash > 100">You will also receive an additional lump sum in relation to back-dated pension of <span class="amount">£{{schemeCalc.arrears_cash | roundDown(100)}}</span>, however this will be taxed as income.</p>
          </div>
        </div>
      </question>
      <question title="Bridge the Gap">
        <div class="container left">
          <p class="animate">You also have the option to receive a higher pension between your date of retirement and your State Pension Age (SPA).  This is known as the Bridge the Gap option.  For further details of this option please read the <a href="https://www.cadentgaspensions.com/storage/app/uploads/public/66a/8ad/a79/66a8ada798001759153814.pdf" target="_blank">Bridge the Gap booklet</a>.</p>
          <div class="cards post-animate">
            <input id="nobridging" type="radio" name="increases" checked="true" v-model="bridgeTheGap" :value="false"/>
            <label class="card" for="nobridging">
              <h4>You would get <span class="amount">£{{ schemeCalc.retirement_pension_no_bridge | roundDown(100) }} pa</span> if you choose a pension that... </h4>
              <p>does not bridge the pension gap between your date of retirement and your State Pension Age</p>
            </label>
            <input id="bridging" type="radio" name="increases" v-model="bridgeTheGap" :value="true"/>
            <label class="card" for="bridging">
              <h4>You would get an initial pension of <span class="amount">£{{ schemeCalc.retirement_pension_with_bridge | roundDown(100) }} pa</span> reducing by <span class="amount">£{{schemeCalc.post_bridge_reduction | roundDown(100)}} pa</span> at SPA...</h4>
              <p>if you choose to bridge the pension gap between your date of retirement and your State Pension Age</p>
            </label>
          </div>
        </div>
      </question>
      <question title="Dependant benefits" :animateText="true">
        <div class="container left">
          <p class="animate sub-title"><b>What will be left to my spouse and dependants?</b></p>
          <p class="animate">If you die during your retirement while married, a pension of £{{schemeCalc.retirement_sp_pen | roundDown(100)}} is payable to your dependant from the day after your death. This pension will increase each year, in line with increases in the cost of living. This is automatically paid to your spouse or civil partner for the rest of their life.</p>
          <p class="animate">If you are not married or in a civil partnership when you die, the dependant’s pension may be payable to someone else, as long as there was proven financial dependency or interdependency at the date of your death.</p>
          <p class="animate">If your death is within five years’ of your pension starting there will also be a lump sum equal to the remainder of the first five years’ payments that would have been payable to you - this is known as a five year guarantee.</p>
          <p class="animate">For further information on the benefits payable on death from the Scheme or to update your nomination form please <a href="https://cadentgaspensions.com/pensioner-members/about-your-pension/guaranteed-minimum-pension/death-benefits" target="_blank">click here</a>.</p>
        </div>
      </question>
      <question title="Summary" :animateText="true">
        <div class="sub-groups">
          <div class="sub-group">
            <p class="animate sub-title left">Regular Income</p>
            <p class="animate left">A pension of £{{schemeCalc.retirement_pension | roundDown(100)}} pa payable from {{retirementAgeShow | age}} <span v-if="bridgeTheGap" >reducing by £{{schemeCalc.post_bridge_reduction | roundDown(100)}} pa at State Pension Age</span> for the rest of your life (increasing in line with increases in the cost of living)</p>
          </div>
          <div class="sub-group">
            <p class="animate sub-title left">Tax lump sum</p>
            <p class="animate left">An initial tax free lump sum of £{{ schemeCalc.retirement_pcls | roundDown(500)}} <span v-if="schemeCalc.avcs_used_in_commutation > 0 || schemeCalc.pension_used_in_commutation>0">(in exchange of <span v-if="schemeCalc.pension_used_in_commutation>0">£{{schemeCalc.pension_used_in_commutation | roundDown(100)}} pension pa</span><span v-if="schemeCalc.avcs_used_in_commutation > 0 & schemeCalc.pension_used_in_commutation>0" > and </span><span v-if="schemeCalc.avcs_used_in_commutation > 0">£{{schemeCalc.avcs_used_in_commutation | roundDown(500)}} from your AVC fund</span>)</span></p>
          </div>
          <div class="sub-group left">
            <p class="animate sub-title">Benefits for my dependant</p>
            <p class="animate left">A pension of £{{schemeCalc.retirement_sp_pen | roundDown(100)}} pa for your spouse, civil partner or in some cases your dependant after your death.</p>
          </div>
        </div>
        <div v-if="changed" class="changed">
            <p class="bold">You changed the following options</p>
            <ul>
                <li v-if="prechanges.cashAmountPercentage != cashAmountPercentage">Amount of tax free cash changed from {{prechanges.cashAmountPercentage/100 | percent}} to {{cashAmountPercentage/100 | percent}}</li>
                <li v-if="prechanges.bridgeTheGap != bridgeTheGap">Bridge the gap option from {{ prechanges.bridgeTheGap? 'true' : 'false'}} to {{ bridgeTheGap? 'true' : 'false'}}</li>
            </ul>
            <p>Before these changes you had an illustrated pension of £{{prechanges.calcs.retirement_pension | roundDown(100)}} pa<span v-if="prechanges.bridgeTheGap"> reducing by £{{prechanges.calcs.post_bridge_reduction | roundDown(100)}} pa at State Pension Age</span>; an initial tax free lump sum of £{{ prechanges.calcs.retirement_pcls | roundDown(500)}}; and, a pension of £{{prechanges.calcs.retirement_sp_pen | roundDown(100)}} pa for your spouse after your death.</p>
        </div>
      </question>
    </questions-flow>
  </template>
</modal>
</template>

<script>
import QuestionsFlow from './common/QuestionsFlow';
import Question from './common/Question';
import vueSlider from 'vue-slider-component';
import Modal from './common/Modal';
import constants from './../constants.js';

export default {
    name: 'Annuity',
    components: { QuestionsFlow, Question, vueSlider, Modal },
    data () {
        return {
            showQuestions: false,
            mounted: false
        };
    },
    mounted () {
        this.mounted = true;
    },
    created () {
        this.prechanges = {
            cashAmountPercentage: this.cashAmountPercentage,
            bridgeTheGap: this.bridgeTheGap,
            calcs: this.schemeCalc
        };
    },
    methods: {
        submit () {
            this.$emit('submit');
        }
    },
    computed: {
        changed () {
            return this.prechanges.cashAmountPercentage != this.cashAmountPercentage || this.prechanges.bridgeTheGap != this.bridgeTheGap;
        },
        user () {
            return this.$store.getters.getUser;
        },
        schemeDetails () {
            return this.$store.getters.getSchemeDetails;
        },
        earliestRetirementAge (){
            return Math.max(this.user.earliest_retirement_age, Math.ceil(this.user.ageNow));
        },
        earliestUnreducedRetirementAge () {
            return this.user.earliest_undreduced_retirement_age;
        },
        sliderMaxAge () {
            return this.user.status == 'active'? this.user.nra : this.earliestUnreducedRetirementAge;
        },
        seeSlider () {
            return this.earliestRetirementAge <= this.sliderMaxAge;
        },
        retirementAge () {
            return this.$store.getters.getRetirementAge;
        },
        schemeCalc () {
            return this.$store.getters.getSchemeCalc;
        },
        retirementAgeShow () {
            return this.retirementAge;
        },
        cashAmountPercentage: {
            get () {
                return this.$store.getters.getSchemePensionCashMaxProp;
            },
            set (newValue) {
                this.$store.dispatch('setSchemePensionCashMaxProp', newValue);
            }
        },
        bridgeTheGap: {
            get () {
                return this.$store.getters.getBridgeTheGap;
            },
            set (newValue) {
                this.$store.dispatch('setBridgeTheGap', newValue);
            }
        },
        currentQuestion () {
            if (this.mounted && this.$refs.questions) {
                return this.$refs.questions.questionNo;
            }
            return '';
        },
        cashSliderDataPoints () {
            var arr = [];
            for (var i = 0; i <= 100; i++) {
                arr.push(i);
            }
            return arr;
        }
    },
    watch: {
        questionNo () {
            this.$store.dispatch('postAnalytics', { measure: constants.Modal_SchemePension_Progress, value: this.questionNo });
        }
    }
};
</script>


<style scoped lang="scss">
@import './../scss/variables.scss';
@import './../scss/mixins.scss';

p.slider-amount {
    font-size: 1.3rem;
    text-align: center;
    margin-top: 1rem;
    width: 100%;
    display: inline-block;
}
.amount {
    color: $nationalGridBlue;
    font-weight: 700;
}
ul {
    list-style: disc;
    li {
        margin-left: 2.5rem;
        line-height: 1.5;
    }
}
a {
    text-decoration: underline;
    color: $nationalGridBlue;
}

input[type="radio"] {
    position: absolute;
    pointer-events: none;
    opacity: 0;
}
input:checked {
    + .card {
        background: $nationalGridLightBlue;
        color: white;
        h4 {
            color: white;
        }
        .amount {
            color: white;
        }
    }
}
.cards {
    display: flex;
    @include responsive (mobile) {
        flex-wrap: wrap;
    }
}
.card {
    padding: 1rem;
    margin: 0.5rem;
    width: 50%;
    box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 4px 1px;
    background: #fff;
    display: block;
    @include responsive (mobile) {
        width: 100%;
    }
    transition: transform 0.5s;
    &:hover {
        cursor: pointer;
        transform: scale(1.01);
    }
    p {
        padding: 0;
    }
    h4 {
        font-weight: 400;
        color: $nationalGridBlue;
        font-size: 1.4rem;
        line-height: 1.5;
    }
    &.selected {
        background: $nationalGridLightBlue;
        color: white;
        h4 {
            color: white;
        }
        .amount {
            color: white;
        }
    }

}
.changed{
    .bold{
        font-weight: bold;
    }
    padding: 0 20px;
    font-size: 1.1rem;
    text-align: left;
}
</style>
